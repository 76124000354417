<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ translations.tcHelp }}</h1>
    </header>
    <b-container class="page-body">
      <b-row align-h="center">
        <b-col sm="10" class="search-col">
          <search-input @handleSearchClick="handleSearchClick"></search-input>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col sm="10" class="search-col"> </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTableSearch.vue'
import SearchInput from '@/components/SearchInput.vue'
import SearchService from '@/services/SearchService'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'site-help',
  mixins: [translationMixin],
  data() {
    return {
      addItems: {},
      fields: [
        {
          key: 'search_results',
          label: 'Results',
        },
      ],
      searchResults: [],
      searchTerm: '',
      selectedFilters: [],
      showResults: false,
      tooltip: 'Testing',
      translations: {},
    }
  },
  async created() {
    await this.page_load()
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async handleSearchClick(searchTerm) {
      try {
        this.setLoadingStatus(true)
        const results = await this.getSearchResults(searchTerm)
        const formattedResults = this.formatForDataTable(results)
        this.searchResults = formattedResults
        this.showResults = true
      } catch (error) {
        console.error(`There was an error retrieving search results ${error} for module ${this.$options.name}`)
        throw error
      } finally {
        this.setLoadingStatus(false)
      }
    },
    getSearchResults(searchTerm) {
      var response = SearchService.getSearchResults(searchTerm, this.prefCulture)
      return response
    },
    handleFAQClick() {
      this.$router.push({ path: '/help/faqs' })
    },
    formatForDataTable(result) {
      return result.data.map((x) => {
        return {
          type: x['Type'],
          ItemID: x['ItemID'],
          Link: x['Link'],
          ItemName: x['ItemName'],
          Name: x['Name'],
          Description: x['Description'],
          VideoParentCategoryCode: x['VideoParentCategoryCode'],
          VideoParentCategory: x['VideoParentCategory'],
          ThumbnailId: x['ThumbnailId'],
          ind_account_id: x['ind_account_id'],
          IsAFriend: x['IsAFriend'],
          IsAMember: x['IsAMember'],
          IsAProspect: x['IsAProspect'],
          IsChurchPresenter: x['IsChurchPresenter'],
          IsTheConUser: x['IsTheConUser'],
          IsWidow: x['IsWidow'],
          JoinDate: x['JoinDate'],
          LastName: x['LastName'],
          FirstName: x['FirstName'],
          SpouseaAccountID: x['SpouseaAccountID'],
          SpouseAMemeber: x['SpouseAMemeber'],
          SpouseFormalName: x['SpouseFormalName'],
          SpouseKey: x['SpouseKey'],
          PreferedName: x['PreferedName'],
          id: x['id'],
          Denomination: x['Denomination'],
          MemberChurchKey: x['MemberChurchKey'],
          MemberChurchName: x['MemberChurchName'],
          Address1: x['Address1'],
          City: x['City'],
          State: x['State'],
          CountryISO: x['CountryISO'],
          IsPlacementFacility: x['IsPlacementFacility'],
          IsChurch: x['IsChurch'],
          IsGideonCardFacility: x['IsGideonCardFacility'],
          IsCamp: x['IsCamp'],
          OrganizationName: x['OrganizationName'],
          OrganizationType: x['OrganizationType'],
          OrgAddress1: x['OrgAddress1'],
          OrgAddress2: x['OrgAddress2'],
          OrgAddress3: x['OrgAddress3'],
          OrgState: x['OrgState'],
          Country: x['Country'],
          CountryAbr: x['CountryAbr'],
          OrgPhoneNumber: x['OrgPhoneNumber'],
          OrgPhoneNumberMasked: x['OrgPhoneNumberMasked'],
        }
      })
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([this.getViewTranslations(), this.getComponentTranslations('data-table-search')]).then(
          (results) => {
            this.$set(this.translations, 'components', results[2])
          }
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    filteredResults() {
      var filteredArr = this.searchResults.filter((r) => {
        return true
      })

      if (this.selectedFilters.length > 0) {
        filteredArr = filteredArr.filter((r) => {
          var pos = this.selectedFilters.indexOf(r.type)
          return pos >= 0
        })
      }

      return filteredArr
    },
  },
  components: {
    SearchInput,
    DataTable,
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
</style>
